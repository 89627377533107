<template>
  <div class="TransferingConsultation">
    <el-button class="bt">专家预约挂号</el-button>
    <div class="content">
      <div class="left">
        <div class="nr">白内障专科</div>
        <div class="nr">青光眼专科</div>
        <div class="nr">眼屈光专科</div>
        <div class="nr">斜视/小儿眼科</div>
      </div>
      <div class="m">
        <div>
          Hospital introduction
        </div>
        <div>
          Hospital introduction
        </div>
      </div>
      <div class="left">
        <div class="nr">干眼诊疗</div>
        <div class="nr">眼底病诊疗</div>
        <div class="nr">角膜病专科</div>
        <div class="nr">眼外伤专科</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferingConsultation",
  created() {
    this.$store.commit('isShow/upBottom', false)
  }
}
</script>

<style scoped lang="scss">
  .TransferingConsultation {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
  }
  .bt {margin-top: 4vh;}
  .content {
    display: flex;
    justify-content: space-between;
    padding: 4vh 10vw;
    .left {
      div {
        width: 9em;
        padding: 10px 0;
        border: 1px solid #8f8e8e;
        border-radius: 10px;
        margin-bottom: 5vh;
      }
    }
    .m {
      div {
        width: 50vw;
        border: 1px solid #8f8e8e;
        height: 13vh;
      }
      div:nth-child(1) {margin-bottom: 5vh;}
    }
  }
</style>